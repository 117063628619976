import React from 'react';
import styled from 'styled-components';
import firebase from 'gatsby-plugin-firebase';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import THEME from 'constants/theme';
import Title from "components/title"
import Paper from "components/paper";
import Logo from "components/logo";


const BlogDetailsTemplate = (props) => {
  const query = useStaticQuery(graphql`
    query GetBlogBySlug($slug: String) {
      contentfulBlog(slug: { eq: $slug }) {
        title
        author
        slug
        content {
          raw
        }
        createdAt
      }
    }
  `);
  React.useEffect(() => {
    firebase.analytics().logEvent(`visited_blog_${query.contentfulBlog.slug}`);
  }, []);
  return (
    <Layout>
      <SEO title={`Web Power - Blog Details - ${query.contentfulBlog.title}`} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: 20,
        }}>
        <Logo />
        <Paper style={{ marginTop: -30 }}>
          <Title>Blog Post</Title>
          <StyledBlogContainer>
            <h1>{query.contentfulBlog.title}</h1>
            <span>By {query.contentfulBlog.author}</span>
            {documentToReactComponents(JSON.parse(query.contentfulBlog.content.raw))}
          </StyledBlogContainer>
        </Paper>
      </div>

    </Layout>
  );
};

const StyledBlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  span {
    width: 100%;
    text-align: left;
    font-weight: 600;
    font-size: 1.2rem;
    color: ${THEME.primary};
    font-family: 'Open Sans', sans-serif;
  }
  h1 {
    color: ${THEME.text};
    font-family: 'Open Sans', sans-serif;
    &:nth-child(1) {
      text-transform: uppercase;
    }
  }
  h2 {
    color: ${THEME.text};
    font-family: 'Open Sans', sans-serif;
    &:nth-child(1) {
      text-transform: uppercase;
    }
  }
  p {
    color: ${THEME.text};
    font-size: 1.2rem;
    font-family: 'Open Sans', sans-serif;
  }
  & ul {
    font-family: 'Open Sans', sans-serif;
    position: relative;
    width: 100%;
    & li {
      position: relative;
    width: 100%;
    }
  }
`;
export default BlogDetailsTemplate;

// #FC5184
// #FC5185
